import Swiper from 'swiper/bundle';
import anime from 'animejs';

export function index(){
  const updateSlide = document.getElementById('updateSlide')
  if(updateSlide){
		new Swiper(updateSlide, {
      slidesPerView: 2,
			spaceBetween: 10,
      centeredSlides: false,
      loop: false,
			speed: 600,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 12,
        },
        768: {
          slidesPerView: 3,
          spaceBetween: 16,
        },
        1024: {
          slidesPerView: 4,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 5,
          spaceBetween: 24,
        },
      },
      navigation: {
        nextEl: ".first__controll.next",
        prevEl: ".first__controll.prev",
      },
    })
  }

  const adsSwiper = document.getElementById('adsSwiper')
  if(adsSwiper){
		new Swiper(adsSwiper, {
      slidesPerView: 1,
			spaceBetween: 10,
      centeredSlides: false,
      loop: true,
			speed: 800,
      autoplay: {
        delay: 4000,
        disableOnInteraction: false,
      },
      breakpoints: {
        640: {
          slidesPerView: 2,
          spaceBetween: 12,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 16,
        },
        1024: {
          slidesPerView: 3,
          spaceBetween: 20,
        },
        1200: {
          slidesPerView: 4,
          spaceBetween: 24,
        },
      },
    })  
  }
};