export function blockquote(){
  const temp = {
    data: function(){
      return {
        toggle: false
      }
    },
    props: {
      url: String,
      name: String,
      ad: {
        type: Boolean,
        default: false,
      },
    },
    template: 
    `
      <div class="quotebox">
        <div v-if="ad">
          <div class="quotebox__btn" @click="toggle = !toggle" :class="{'active': toggle}">広告元情報</div>
          <div class="quotebox__item" v-if="toggle">
            <blockquote>
              <p>【広告元】 <span v-html="name"></span></p>
            </blockquote>
          </div>
        </div>
        <div v-else>
          <div class="quotebox__btn" @click="toggle = !toggle" :class="{'active': toggle}">引用元情報</div>
          <div class="quotebox__item" v-if="toggle">
            <p>掲載しているサムネイル画像は下記のURLより引用しております。</p>
            <blockquote>
              <p>【引用元】 <span v-html="name"></span></p>
              <cite><a :href="url" v-html="url" target="_blank" rel="nofollow"></a></cite>
            </blockquote>
          </div>
        </div>
      </div>
    `
  }
  return temp;
};