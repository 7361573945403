import Vue from 'vue/dist/vue.min';
import axios from 'axios';
import {blockquote} from './components/blockquote';
import {r18} from './components/r18';

export function vm(){
    const v = new Vue({
      el: '#site-app',
      data: {
        titsTime: true,
        modal: false,
        twitterCard: '',
        twitterQuort: '',
        twitterThumb: '',
        searchitem: false,
        videocate: [],
        videotype: [],
        searchResult: 0,
        searchInput: '',
        setting: {
          mode: true,
          modal: true,
          size: true,
          fit: false,
          hand: true,
          escape: false
        },
        escapeBackup: {
          title: '',
          favicon: ''
        }
      },
      mounted: function(){
        this.setSetting()
        this.displayEscape()
      },
      methods: {
        tits: function(e){
          if(this.titsTime){
            e.target.classList.add('active')
            this.titsTime = false
            setTimeout(()=>{
              e.target.classList.remove('active')
              this.titsTime = true
            },200)
          }
        },
        callmodal: function(str, quote, thumb){
          if(this.setting.modal){
            this.modal = true
            this.cardChange(str, quote, thumb)
          }else{
            this.modal = false
            window.open(str, '_blank');
          }
        },
        cardChange: function(str, quote, thumb){
          this.twitterCard = str
          this.twitterQuort = quote
          this.twitterThumb = thumb
          
          const modalBox = document.querySelector('.modalBox__inner__card')
          modalBox.innerHTML = ''

          const video = document.createElement('video')
          video.muted = true
          video.setAttribute('autoplay', 'autoplay');
          video.setAttribute('loop', 'loop')
          video.setAttribute('playsinline', 'playsinline')
          video.controls = true
          video.src = this.twitterCard;
          video.poster = this.twitterThumb;
          video.className = 'twitter-tweet'
          video.addEventListener('loadedmetadata', ()=> {
            modalBox.appendChild(video);
            this.getDl(modalBox)
            this.getAds(modalBox)
          });      
          video.load();
        },
        getDl: function(el){
          const dl = document.createElement('dl')
          dl.className = 'modalBox__inner__card__quart'

          const dt = document.createElement('dt')
          dt.innerText = '引用元URL'

          const dd = document.createElement('dd')
          dd.innerText = this.twitterQuort

          const ddlink = document.createElement('dd')
          ddlink.className = 'link'

          const ddurl = document.createElement('a')
          ddurl.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 512 512"><path d="M432 64H208c-8.8 0-16 7.2-16 16V96H128V80c0-44.2 35.8-80 80-80H432c44.2 0 80 35.8 80 80V304c0 44.2-35.8 80-80 80H416V320h16c8.8 0 16-7.2 16-16V80c0-8.8-7.2-16-16-16zM0 192c0-35.3 28.7-64 64-64H320c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V192zm64 32c0 17.7 14.3 32 32 32H288c17.7 0 32-14.3 32-32s-14.3-32-32-32H96c-17.7 0-32 14.3-32 32z"/></svg>参照元を見る';
          ddurl.href = this.twitterQuort;
          ddurl.target = '_blank';
          ddurl.rel = 'nofollow';
          ddlink.appendChild(ddurl)
          dl.appendChild(dt)
          dl.appendChild(dd)
          dl.appendChild(ddlink)
          el.appendChild(dl)
        },
        getAds: function(el){
          const url = '/wp-json/custom/v1/ad'
          axios.get(url)
          .then((res) => {
            const data = res.data.data.ad
            const dom = document.createElement('div')
            dom.className = 'ad'
            dom.style.margin = '0 0 1.5rem'
            dom.innerHTML = data
            el.insertBefore(dom, el.firstChild);
          })
          .catch((error) => {
            console.log(error)
          });
        },
        close: function(){
          this.modal = false
          this.twitterCard = ''
          const twitterTweet = document.querySelector('.twitter-tweet')
          if(twitterTweet){
            document.querySelector('.twitter-tweet').remove()
          }
        },
        postSrarch: function(){
          window.location.href = this.searchInput;
        },
        getSearchCount: function(){
          const url = '/wp-json/custom/v1/video-count'
          let hash = '?'
          if(this.videocate.length > 0){
            hash += 'videocate='
            this.videocate.forEach((elem , index) => {
              if(index != 0){
                hash += ','
              }
              hash += elem
            })
          }

          if(this.videocate.length > 0 && this.videotype.length > 0){
            hash += '&'
          }

          if(this.videotype.length > 0){
            hash += 'videotype='
            this.videotype.forEach((elem , index) => {
              if(index != 0){
                hash += ','
              }
              hash += elem
            })
          }

          this.searchInput = '/result/' + hash.replace(/,/g, '%');

          if(hash == '?'){
            this.searchResult = 0
          }else{
            axios.get(url + hash)
            .then((res) => {
              const data = res.data.video_count
              this.searchResult = data
            })
            .catch((error) => {
              this.searchResult = 0
            });
          }
        },
        setSetting: function(){
          for (const key in this.setting) {
            if (this.setting.hasOwnProperty(key)) {
              const storageValue = localStorage.getItem(key)
              if(storageValue){
                this.setting[key] = storageValue === "true";
              }
            }
          }
        },
        changeSetting: function(){
          for (const key in this.setting) {
            if (this.setting.hasOwnProperty(key)) {
                const value = this.setting[key]
                localStorage.setItem(key, value)
              }
          }
        },
        displayEscape: function(){
          this.escapeBackup.title = document.title
          this.escapeBackup.favicon = document.querySelector('link[rel="icon"]').href

          document.addEventListener('keydown', (event) => {
            if ((event.key === ' ' || event.key === 'Spacebar') && event.shiftKey) {
              const wrapper = document.getElementById('site-app')
              if(wrapper && this.setting.escape){
                wrapper.classList.toggle('escape')
                if(wrapper.classList.contains('escape')){
                  this.escapeMeta('Page Not Found', '/images/common/favicon_alt.ico')
                }else{
                  this.escapeMeta(this.escapeBackup.title, this.escapeBackup.favicon)
                }
              }else{
                wrapper.classList.remove('escape')  
              }
            }
          })
        },
        escapeMeta: function(title, favicon){
          const faviconElement = document.querySelector('link[rel="icon"]')
          const faviconElementAlt = document.querySelector('link[rel="shortcut icon"]')
          document.title = title;

          if(faviconElement){
            faviconElement.href = favicon
          }
          if(faviconElementAlt){
            faviconElementAlt.href = favicon
          }
        }
      },
      components: {
        quote: blockquote(),
        r18: r18(),
      }
    });
};