import ScrollMagic from 'scrollmagic';
import {scrolEvents} from './default';
import {index} from './index';
import {vm} from './vm';


document.addEventListener('DOMContentLoaded', function() {
	let scene = [];
	let controller;
    controller =  new ScrollMagic.Controller();
    
    vm();
    scrolEvents(controller , scene);
    index(controller , scene);
    
    const app = document.getElementById('site-app')
    const nextSibling = app.nextElementSibling;
    if (nextSibling) {
      nextSibling.classList.add('adBlockElem');
    }
});
